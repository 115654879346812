@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("../../themes/Colors.css");

body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif !important;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  /* background-color: #D7E4F3; */
  background-color: var(--background);
}

.ant-dropdown-menu {
  padding: 8px 0;
  border-radius: 10px;
}

.ant-dropdown-menu:empty {
  padding: 0px;
}
.suggestion-item--active{
  background-color: var(--lightPrimary)!important;
}
/* MODALS */
.textDiscount{
  color: var(--primary);
}
@media only screen and (max-width: 768px) {
  .ant-modal {
    top: 0px;
  }
}

.first-capitalize::first-letter {
  text-transform: uppercase !important;
}
.first-capitalize {
  text-transform: lowercase !important;
  color: var(--primary);
}

.ant-input:focus {
  border-color: var(--primary);
  box-shadow: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary);
}

/* Change the border color on mouse hover */
input:hover {
    border-color: var(--primary) !important; /* Changes the border color on mouse hover */
}

/* Change the border color on mouse hover */
textarea:hover {
    border-color: var(--primary) !important; /* Changes the border color on mouse hover */
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-close {
  color: var(--grayMedium);
}

.ant-modal-body {
  padding: 0px;
}

.videoModal .ant-modal-content {
  border-radius: 0px;
}

.videoModal {
  width: 80% !important;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .videoModal {
    width: 95% !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-calendar-picker-container {
    width: 100%;
    overflow-x: auto;
  }
}
.ant-alert-info .ant-alert-icon {
  color: var(--primary);
}
.ant-alert-with-description {
  background-color: var(--extraLightPrimary);
}
.ant-alert-info {
  border: 1px solid var(--extraLightPrimary);
}

.videoModal .ant-modal-body {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.videoModal .ant-modal-body iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modalQuote {
  background-image: url("../../images/bg2.jpg");
  background-size: cover;
  height: 80px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  align-items: flex-end;

  padding: 25px 25px 15px;
}

.modalQuote h2 {
  font-size: 25px;
  font-weight: 600;
  color: var(--snow);
  margin-bottom: 0px;
}

.modalCanva {
  background-image: url("../../images/home-bk.png");
  background-size: cover;
  height: 80px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  align-items: flex-end;

  padding: 25px 25px 15px;
}

.modalCanva h2 {
  font-size: 22px;
  font-weight: 600;
  color: var(--snow);
  margin-bottom: 0px;
}

.modalHeader {
  background-image: var(--backgroundImageLogin);
  background-size: cover;
  height: 250px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  align-items: flex-end;

  padding: 25px 25px 15px;
}

.registration-type-modal{
  width: 45rem !important;
}

.registration-type-modal .modalContent{
  height: 25rem !important;
}

.ant-form-item {
  width: 100%;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: var(--grayMedium);
}

.modalHeader h2 {
  font-size: 25px;
  font-weight: 600;
  color: var(--snow);
  margin-bottom: 0px;
}

.modalHeader .modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modalHeader.modal-title h2 {
  text-align: center;
  margin: 0;
}

.modalContent {
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalContent p {
  color: var(--primary);
  font-weight: 600;
  padding: 0 15px;
}

.modalContent p.errors {
  color: var(--red);
}

.modalInput .ant-input {
  border: 2px solid var(--coal);
  border-radius: 8px;
  background-color: var(--snow);
  color: var(--coal);
  height: 40px;
}

.modalInput-autocomplete {
  border: 2px solid var(--coal);
  border-radius: 8px;
  background-color: var(--snow);
  color: var(--coal);
  height: 40px;
  width: 100%;
  position: relative;
}

.modalInput-autocomplete .ant-select-auto-complete .ant-select-selection {
  border: none;
  box-shadow: none;
  background: transparent;
}

.modalInput-autocomplete .ant-input {
  background-color: #f5f5f5;
  border: none;
  border-radius: 20px;
  height: 40px;
  padding-left: 15px;
}

.modalInput-autocomplete .ant-input-prefix {
  margin-right: 8px;
}

.modalInput-autocomplete .ant-input-suffix {
  margin-left: 8px;
}

.modalInput-autocomplete .ant-input::placeholder,
.certain-category-search .ant-input::placeholder {
  padding-left: 15px;
}

.modalInput-autocomplete .ant-select-selection__placeholder{
  padding-left: 20px;
}

.modalInput .ant-input:hover,
.modalInput .ant-input:focus {
  border-right-width: 2px !important;
}

.modalInput .has-error .ant-form-explain,
.modalInput .has-error .ant-form-split {
  margin: 2px 8px;
}

.modalInput.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--primary);
  border-right-width: 2px !important;
}

.modalInput input:focus,
.modalInput input[type="text"]:focus,
.modalInput input[type="password"]:focus,
.modalInput input[type="email"]:focus,
.modalInput input[type="number"]:focus,
.modalInput textarea:focus {
  border: 2px solid var(--primary);
  color: var(--coal);
}

.modalInput .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border: 2px solid var(--coal);
}

.modalInput .ant-input:focus {
  box-shadow: none;
}

.modalInput.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}

.modalInput.ant-input-affix-wrapper {
  font-size: 18px;
}

.modalContent a {
  color: var(--primary);
}

.modalContent a span {
  color: var(--coal);
}

.modalButton {
  width: 45%;
  height: 50px;
  margin: 15px 0 0;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 10px;
  color: var(--snow);
  font-size: 20px;
  font-weight: 700;
}

.modalButtonCompany {
  width: 60%;
  height: 50px;
  margin: 15px 0 0;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 10px;
  color: var(--snow);
  font-size: 20px;
  font-weight: 700;
}

.modalButtonForgoten {
  width: 60%;
  height: 50px;
  margin: 15px 0 0;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 10px;
  color: var(--snow);
  font-size: 20px;
  font-weight: 700;
}

.loginErrors {
  color: var(--red);
  font-weight: 600;
}

.modalSettings .ant-modal-close {
  color: var(--grayMedium);
}

.modalSettingsHeader {
  padding: 25px 25px 15px;
}
.modalSettingsHeader h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
}
.modalSettingsContent {
  padding: 0 25px 25px;
}

.modalSettingsButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSettingsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  margin: 15px 0 0;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 10px;
  color: var(--snow);
  font-weight: 700;
}

.cardInfo {
  font-size: 15px;
  font-weight: 600;
  color: var(--coal);
  padding: 0 0 15px;
}
.cardInfoPrimary {
  font-size: 13px;
  color: var(--primary);
  font-weight: 600;
}

/*DRAWER*/

.ant-drawer-content {
  background-color: #f1f2f2;
}

.drawerContainer {
}

.drawerContainer h5 {
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.drawerContainer p {
  color: var(--secondary);
  margin-bottom: 0;
}
.drawerContainer h3 {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.uploadButton {
  width: 100%;
  height: 40px;
  margin: 0 0 15px;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 5px;
  color: var(--snow);
  font-size: 16px;
  font-weight: 700;
}
.quoteButton {
  width: 100%;
  height: 40px;
  margin: 0 0 15px;
  background-color: #f1f2f2;
  border-color: var(--primary);
  border-radius: 5px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
}
.alertCurrency {
    margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.alertCurrency span{
  color: rgb(0 0 0 / 60%) !important;
 }
.quoteButton:hover {
  background-color: var(--primary);
  color: var(--snow);
}

.drawer-link .logo {
  margin: 0 0 10px;
}

.drawer-link {
  width: 100%;
  font-size: 15px;
  margin: 0 0 10px;
}

/* POPOVER */

.popover {
  display: flex;
  flex-direction: column;
}

.ant-popover {
  position: fixed;
}

.popover .range {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.popover .ant-checkbox-wrapper {
  margin-left: 8px;
}

.popover .companySelect {
  width: 200px !important;
  margin: 0 0 15px;
}
.popover brandSelect {
  width: 200px !important;
  margin: 0 0 15px;
}
.popover .ant-select-selection--single {
  height: 39px;
  border-radius: 10px;
  border-color: burlywood;
}
.popover .ant-select-selection__rendered {
  line-height: 35px;
  border-color: burlywood;
}

/* DATA RANGE */
.popover .DateRangePickerInput_calendarIcon_svg {
  fill: var(--coal);
}

.popover .DateRangePickerInput_calendarIcon:focus {
  outline: none;
}

.popover .DateRangePicker {
  width: 100%;
  border-radius: 10px;
}
.popover .DateRangePickerInput__withBorder {
  border: 0px;
  width: 100%;
  border-radius: 10px;
}
.popover .DateInput {
  min-width: 170px;
  border-radius: 10px;
}
.popover #your_unique_start_date_id {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.popover #your_unique_end_date_id {
  border-left: 1px solid #ddd;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.popover .DateInput_input {
  border-bottom: 0px;
  color: var(--coal);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
.popover .DateInput_input__focused {
  border-bottom: 0px;
}
/* CALENDAR DAY */
.popover .CalendarDay__selected,
.popover .CalendarDay__selected:active,
.popover .CalendarDay__selected:hover {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--snow);
}

.popover .CalendarDay__selected_span {
  background: var(--lightPrimary);
  border: 1px solid var(--lightPrimary);
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: var(--primary);
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover{
  background: var(--primary);
}
.ant-calendar-cell ant-calendar-selected-end-date ant-calendar-selected-day{
  background: var(--primary);
}
.ant-calendar-range .ant-calendar-in-range-cell::before{
  background: var(--lightPrimary);
}

.popover .CalendarDay__selected_span:active,
.popover .CalendarDay__selected_span:hover {
  background: var(--lightPrimary);
  border: 1px solid var(--lightPrimary);
  color: var(--snow);
}

.popover .CalendarDay__hovered_span,
.popover .CalendarDay__hovered_span:hover {
  background: var(--lightPrimary);
  border: 1px solid var(--lightPrimary);
  color: var(--snow);
}

.popover .DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid var(--primary);
}

.popover .DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid var(--secondary);
}

.DateRangePicker_picker__portal {
  z-index: 99999 !important;
}

.ant-upload-list-item {
  margin-top: 0px;
  margin-bottom: 8px;
}

.ant-upload-list-item-info {
  padding: 0 16px 0 0px;
  margin-bottom: 4px;
}
.disabledCart{
  pointer-events: none;
  opacity: 0.7;
}
.disabledCartAlert{
  opacity: 0.9 !important;
}
.textResponsive{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* DATA RANGE */
.dataRange {
  display: flex;
}

.dataRange .DateRangePickerInput_calendarIcon_svg {
  fill: var(--coal);
}

.dataRange .DateRangePickerInput_calendarIcon:focus {
  outline: none;
}

.dataRange .DateRangePicker {
  width: 100%;
  border-radius: 10px;
}
.dataRange .DateRangePickerInput__withBorder {
  border: 0px;
  width: 100%;
  border-radius: 10px;
}
.dataRange .DateInput {
  min-width: 170px;
  border-radius: 10px;
}
.dataRange #your_unique_start_date_id {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.dataRange #your_unique_end_date_id {
  border-left: 1px solid #ddd;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.dataRange .DateInput_input {
  border-bottom: 0px;
  color: var(--coal);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
.dataRange .DateInput_input__focused {
  border-bottom: 0px;
}

.link{
  color: var(--primary)
}

.link:hover{
  color: var(--lightPrimary)
}
.ant-btn::before {
  background: transparent;
}

.ant-btn:focus,.ant-btn:hover {
    color: var(--primary);
    background-color: #fff;
    border-color: var(--primary);
}
.ant-btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.ant-btn-primary:hover,.ant-btn-primary:focus{
  border-color: var(--lightPrimary);
  background-color: var(--lightPrimary);
  color: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: var(--primary);
  border-color: var(--primary);
}
.ant-switch-checked {
  background-color: var(--primary);
}
::selection {
  background: var(--primary);
  background-color: var(--primary);
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item-active a:focus,
.ant-pagination-item a:hover,
.ant-pagination-item a:focus {
  color: var(--primary);
  border-color: var(--primary);
}
.ant-radio-button-wrapper-checked::before{
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color:  var(--primary) !important;
}
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
    background: #fff;
    border-color: var(--primary);
    color: var(--primary);
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    background: #fff;
    border-color: var(--primary);
    color: var(--primary);
}

.cardInfoLink a{
  color: var(--primary);
}
.ant-tabs-ink-bar{
  background-color: var(--primary);
}
.ant-tabs-nav .ant-tabs-tab-active {
    color: var(--primary);
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--primary);
}
.ant-tabs-ink-bar{
  color: var(--primary);
}

.ant-select-selection:hover, .ant-select-selection:focus{
    border-color: var(--primary);
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-diseabled),
.ant-select-dropdown-menu-item-active:hover:not(.ant-select-dropdown-menu-item-diseabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-diseabled){
  background-color: var(--extraLightPrimary);
}
.ant-select-dropdown-menu-item-active{
  background-color: var(--extraLightPrimary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--primary);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:first-child {
  border-color: var(--primary);
}
.ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:focus{
  border-color: var(--primary);
}
.typePurchaseRadioButtonProgrammatic:hover,
.typePurchaseRadioButtonProgrammatic:focus{
  color: var(--primary);
}
.typePurchaseRadioButtonTraditional:hover,
.typePurchaseRadioButtonTraditional:focus{
  color: var(--primary);
}

.solidColorButton.typePurchaseRadioButtonProgrammatic.ant-radio-button-wrapper-checked {
  background-color: #d5dfe9;
}

.solidColorButton.typePurchaseRadioButtonTraditional.ant-radio-button-wrapper-checked {
  background-color: #d5dfe9;
}

.ant-radio-button-wrapper:hover{
  color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 var(--primary);
}
.ant-radio-button-wrapper:hover{
  color: var(--primary);
  border-color: var(--primary);
}
.ant-radio-button-wrapper:hover{
  color: var(--primary) !important;
  border-color: var(--primary)!important;
}
.ant-radio-button-wrapper-checked::before{
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color:  var(--primary) !important;
}

ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus {
    color: var(--primary);
    border-color: var(--primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-checked::after{
  border: 1px solid var(--primary) !important;
}

.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color:  var(--primary) ;
    border-color:  var(--primary) ;
}
.ant-radio-button-wrapper ant-radio-button-wrapper-checked:hover{
    color: #fff;
    background-color: var(--primary) ;
    border-color: var(--primary) ;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:hover:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color: var(--lightPrimary);
    border-color: var(--lightPrimary);
}
.ant-radio-group-solid > .ant-radio-button-wrapper:focus,
.ant-radio-group-solid > .ant-radio-button-wrapper:hover{
  color: rgb(75, 233, 8);
  border: 1px solid #d9d9d9 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--primary) !important;
    opacity: 0.1;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked .ant-radio-inner:hover {
    border-color: var(--primary) !important;
}
.ant-radio-inner::after {
    background-color: var(--primary) !important;
}

.ant-pagination-next .ant-pagination-item-link:hover,
.ant-pagination-prev .ant-pagination-item-link:hover{
  color: var(--primary);
  border-color: var(--primary);
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: var(--primary);
}

.ant-progress-success-bg{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #52c41a;
}

.text-uppercase{
  text-transform: uppercase;
}

.ant-tag-blue {
  color: var(--primary);
  background: var(--extraLightPrimary);
  border-color: var(--lightPrimary);
}
.ant-alert-warning {
  border: 1px solid var(--lightPrimary);
}

html {
  --antd-wave-shadow-color: var(--primary);
}

/* spinner */
.ant-spin-dot-item {
  background-color: var(--primary) !important;
}

/* animations */
.appear-animation {
  animation: appear 0.3s;
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.appear-animation-without-movement {
  animation: appearOpacity 0.5s ease-in-out forwards;
}
@keyframes appearOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.error-message {
  color: #f5222d !important;
}

.modal-title {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-title h2 {
  text-align: center;
  margin: 0;
  margin-top: 25px;
  width: 100%;
}

